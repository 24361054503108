import './App.scss';
import Header from "./components/Header/header";

import { Container } from 'reactstrap';
import Petition from "./components/Petition/petition";
import Authors from "./components/Authors/authors";

function App() {
  return (
    <div className="App">
      <Container>
        <Header />
        <Petition />
        <Authors />
      </Container>
    </div>
  );
}

export default App;
