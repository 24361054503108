import React from 'react';
import {Row} from 'reactstrap';

import './authors.scss';

function Authors() {
  return (
    <Row>
      <div className="col-xs-12 col-lg-3">
        &nbsp;
      </div>
      <div className="col-xs-12 col-lg-9">
        <div className="authors-wrapper">
          <h4>Signatories:</h4>
          <ul className="list-unstyled">
            <li>Robert Adler M.D, Professor of Pediatrics, Keck School of Medicine</li>
            <li>Kenneth S. Alexander, Professor of Mathematics</li>
            <li>Agnes Andor, Assistant Professor of Clinical Management and Organization</li>
            <li>Marco Aponte-Moreno, Professor of Clinical Management and Organization</li>
            <li>Richard Arratia, Professor of Mathematics</li>
            <li>Sepehr Aziz, Clinical Assistant Professor of Psychiatry and the Behavioral Sciences</li>
            <li>Lisa Aziz-Zadeh, Professor, Department of Psychology and Division of Occupational Science and Occupational Therapy</li>
            <li>Jonathan Barnett, Professor, Gould School of Law</li>
            <li>Ann Barnett, MD, Clinical Assistant Professor of Emergency Medicine (Clinician Educator), Keck School of Medicine</li>
            <li>Itzhak Bars, Professor of Physics and Astronomy</li>
            <li>Eyal Ben-Isaac, MD, Associate Professor of Clinical Pediatrics, Keck School of Medicine and CHLA</li>
            <li>Andrea Belz, Research Director, Information Sciences Institute and Professor of Practice, Industrial and Systems Engineering</li>
            <li>Bérénice A Benayoun, Assistant professor of Gerontology</li>
            <li>Arvind Bhambri, Affiliate Research Scientist, USC Marshall Center for Effective Organizations</li>
            <li>Marlon G. Boarnet, Professor of Urban Planning and Spatial Analysis, Price School of Public Policy</li>
            <li>David C. Bohnett, Professor of Social Entrepreneurship</li>
            <li>Steve Caplan, Adjunct Instructor, Annenberg</li>
            <li>Douglas G. Capone, Professor of Environmental Biology</li>
            <li>Peter Carnevale, Professor of Management and Organization, Marshall School of Business</li>
            <li>Sharon A Cermak Professor Occupational Science and Occupational Therapy</li>
            <li>Mark Chaisson, Assistant Professor, Quantitative and Computational Biology</li>
            <li>Melanie Cherry, Lecturer, Annenberg School of Communication</li>
            <li>Irene Chiolo, Gabilan Associate Professor of Biological Sciences</li>
            <li>Ruth Cislowski, Associate Professor of Social Work Practicum Education, Suzanne Dworak-Peck School of Social Work</li>
            <li>Julie Culver, Instructor of Clinical Medicine, Keck School of Medicine</li>
            <li>Thomas G. Cummings, Professor of Management &amp; Organization</li>
            <li>Jahan Dawlaty, Professor of Chemistry</li>
            <li>Jennifer Deal, Senior Research Scientist, USC Marshall Center for Effective Organizations</li>
            <li>Dion Dickman, Associate Professor of Biological Sciences</li>
            <li>Kristin Diehl, Professor of Marketing</li>
            <li>Rosa Di Felice, Professor of Physics and Astronomy</li>
            <li>Vita Droutman, PhD, Lecturer of Psychology</li>
            <li>Tanaz Ferzandi, MD, Director of Urogynecology and Reconstructive Pelvic Surgery</li>
            <li>Julian Domaradzki, Professor, Department of Aerospace and Mechanical Engineering</li>
            <li>Nofar Duani, Instructor of Marketing</li>
            <li>Linda Duguay, Research Associate Professor, Emerita of Biological Sciences</li>
            <li>Leah Duker, Assistant Professor of Occupational Science &amp; Occupational Therapy</li>
            <li>Shantanu Dutta, Dave and Jeanne Tappan Chair in Marketing, Professor of Marketing</li>
            <li>Sherwood H. Egbert, USC Hillel Board Member and Alumni</li>
            <li>Rabbi Denise L. Eger, class of 1982</li>
            <li>Robert English, International Relations-Environmental Studies</li>
            <li>Dmitry Eremin, Postdoctoral Fellow (Chemistry)</li>
            <li>Jake Ettinger (Class of 2018)</li>
            <li>Daniel C. Fehder, Assistant Professor of Management and Organization</li>
            <li>Donald Feinstein MD, MACP Emeritus Professor of Medicine</li>
            <li>Sandra Ferrari Disner, Associate Professor of Linguistics (Teaching)</li>
            <li>Stacey Finley, Associate Professor of Biomedical Engineering</li>
            <li>Peer Fiss, Jill and Frank Fertitta Chair in Business Administration, Professor of Management &amp; Organization, and Sociology</li>
            <li>Henryk Flashner, Professor, Professor, Aerospace and Mechanical Engineering</li>
            <li>Rachel Friedmann, DO, Fellow, CHLA</li>
            <li>Eric M. Friedlander, Dean's Professor of Mathematics</li>
            <li>Susan Friedlander, Professor of Mathematics</li>
            <li>Jason Fulman, Professor of Mathematics</li>
            <li>Natalia Gelfand, Postdoctoral Researcher</li>
            <li>Patricia A George, Adjunct Professor of Psychology</li>
            <li>Shana W. Glassman BA '93</li>
            <li>Larry Goldstein, Professor of Mathematics</li>
            <li>Leana Golubchik, Stephen and Etta Varra Professor, and Professor of Electrical and Computer Engineering and Computer Science</li>
            <li>Raina Greenfest, Parent of USC Student</li>
            <li>Joseph Greenfield, Associate Professor of Practice, Information Technology Program, Viterbi School of Engineering</li>
            <li>Mike Gruntman, Professor of Astronautics</li>
            <li>William G.J. Halfond, Professor of Computer Science, Viterbi School of Engineering</li>
            <li>Lawrence Harris, Fred V. Keenan Chair in Finance, Marshall School of Business</li>
            <li>Susan Hess, Associate Professor of Social Work Practicum Education</li>
            <li>Judith Hirsch, Professor of Biological Sciences</li>
            <li>Ellis Horowitz, Professor of Computer Science &amp; Electrical Engineering Systems</li>
            <li>Rahul Jain, Professor or Electrical and Computer Engineering, and Computer Science</li>
            <li>Robert Jacobs, Emeritus Professor, Department of Pediatrics, USC School of Medicine</li>
            <li>David E. James, Emeritus Professor, School of Cinematic Arts</li>
            <li>Atoosa Javaheri, PSY.D., Keck Medicine of USC</li>
            <li>Marynell Jelinek, MD, Clinical Assistant Professor of Emergency Medicine (Clinician Educator), USC Keck School of Medicine</li>
            <li>Gabriel Kahn, Professor of Professional Practice, Journalism</li>
            <li>Richard Kahn Emeritus Associate Professor</li>
            <li>Lynn Kamerlin, Professor, Georgia Institute of Technology (former USC postdoc)</li>
            <li>Seva Katritch, Associate Professor of Quantitative &amp; Computational Biology</li>
            <li>Steve A. Kay, Director of Convergent Biosciences, Provost Professor of Neurology, Biomedical Engineering and Biological Sciences</li>
            <li>Peter H. Kim, Professor of Management and Organization</li>
            <li>Minjeong Ko, Marshall School of Business, Marketing</li>
            <li>Rex Kovacevich, Professor of Clinical Marketing</li>
            <li>Bart Kosko, Professor of Electrical and Computer Engineering</li>
            <li>Vitaly Kresin, Professor of Physics</li>
            <li>Anna I. Krylov, USC Associates Chair in Natural Sciences and Professor of Chemistry, Member, International Academy of Quantum Molecular Science, Foreign Member, Academia Europaea</li>
            <li>Clive Lennox, USC Accounting Associates Professor of Accounting, Marshall School of Business, Leventhal School of Accounting</li>
            <li>Alec Levenson, Senior Research Scientist, USC Marshall Center for Effective Organizations</li>
            <li>Morris Levy, Associate Professor, Department of Political Science and IR</li>
            <li>Kristina Lerman, Research Professor of Computer Science and Principal Scientist at USC Information Sciences Institute</li>
            <li>Emily Liman, Professor of Biological Sciences</li>
            <li>Jacqueline Liu, Adjunct Instructor, USC Annenberg School for Communication and Journalism</li>
            <li>Richard Lubman, M.D., Associate Professor of Medicine</li>
            <li>Adam MacLean, Assistant Professor of Quantitative and Computational Biology</li>
            <li>Nikhil Malik, Assistant Professor of Marketing</li>
            <li>Fyodor Malikov, Professor of Mathematics</li>
            <li>Thomas Mathew, Loker Hydrocarbon Research Institute and Department of Chemistry</li>
            <li>Kyle Mayer, Chair and Professor of Management and Organization</li>
            <li>Dina Mayzlin, Robert E. Brooker Professor of Marketing</li>
            <li>Serghei Mangul, Assistant Professor of Clinical Pharmacy and Quantitative and Computational Biology</li>
            <li>Peter C. Mancall, Distinguished Professor, Professor of History, Anthropology, and Economics</li>
            <li>Nicholas Mancuso, Assistant Professor of Population and Public Health Sciences</li>
            <li>Megan McCain, Associate Professor of Biomedical Engineering</li>
            <li>Andrew P. McMahon WM Keck Provost and University Professor of Stem Cell Biology and Regenerative Medicine</li>
            <li>Jerry M. Mendel, Emeritus Professor of Electrical Engineering</li>
            <li>Stanislav Minsker, Department of Mathematics</li>
            <li>Milan Miric, Assistant Professor of Data Sciences and Operations, Marshall School of Business</li>
            <li>Michael A Mische, Senior Lecturer, Marshall School of Business</li>
            <li>James E. Moore, II, Emeritus Professor of Industrial Engineering, Civil Engineering, and Public Policy</li>
            <li>Michàlle E. Mor Barak, Dean Endowed Professor of Social Work and Business, SDP School of Social Work and Marshall School of Business</li>
            <li>Nick Morr, Former Lecturer, Annenberg School for Communication and Journalism</li>
            <li>Alan L. Nager,  MD, MHA, Director, Emergency &amp; Transport Medicine, Children's Hospital Los Angeles, Professor of Pediatrics, Keck School of Medicine</li>
            <li>Shri Narayanan, University Professor and Nikias Chair in Engineering</li>
            <li>Paul K. Newton, Professor of Aerospace &amp; Mechanical Engineering, Mathematics, Quantitative and Computational Biology</li>
            <li>Joseph C. Nunes, Professor of Marketing &amp; Joseph A. DeBell Professorship in Business Administration</li>
            <li>Assad Oberai, Hughes Professor and Professor of Aerospace and Mechanical Engineering</li>
            <li>Amy F. Ogata, Professor of Art History</li>
            <li>Janet Oldak, Professor of Biomedical Sciences and Bioengineering, Herman Ostrow School of Dentistry and Viterbi School of Engineering</li>
            <li>Etan Orgel, MD MS, Professor of Clinical Pediatrics, Keck School of Medicine</li>
            <li>Antonio Ortega, Professor of Electrical and Computer Engineering</li>
            <li>Daphna Oyserman, Dean’s Professor of Psychology</li>
            <li>Richard S. Panush, MD, Master, American College of Physicians, Master, American College of Rheumatology, Professor of Medicine</li>
            <li>Marco Papa, Senior Lecturer of Computer Science</li>
            <li>Arielle Paul, MD Assistant Professor of Clinical Emergency Medicine</li>
            <li>Matt Pennell, Associate Professor of Quantitative and Computational Biology</li>
            <li>Daniel Pollack, Professor of Piano, Thornton School of Music</li>
            <li>Stefan Pollack, Adjunct Professor, Annenberg</li>
            <li>Kirill Prozument, Argonne National Laboratory; USC Department of Chemistry Alumnus </li>
            <li>G. K. Surya Prakash, Professor and George and Judith Olah Nobel Laureate Chair in Hydrocarbon Chemistry</li>
            <li>Ghanshyam P. Purohit, Adjunct Professor, Deparment of Astronautical Engineering, Viterbi School of Engineering</li>
            <li>Gandhi Puvvada, Professor, Ming Hsieh Department of Electrical and Computer Engineering, VSoE</li>
            <li>Dave Quast, Adjunct Instructor, Annenberg</li>
            <li>Hanna Reisler, University Professor, Lloyd Armstrong Jr. Chair in Science and Engineering, Member, American Academy of Arts and Sciences</li>
            <li>Alexandra Reisman, Executive Director of Health Initiatives</li>
            <li>Garrett Reisman, Ph.D., Professor of Practice, Astronautical Engineering, Viterbi School of Engineering</li>
            <li>Cheryl Resnik, Professor of Clinical Physical Therapy</li>
            <li>William Resh, C. C. Crawford Associate Professor of Management and Performance, Sol Price School of Public Policy</li>
            <li>Steven Richeimer, MD, Professor of Anesthesiology</li>
            <li>Remo Rohs, Professor and Chair of Quantitative and Computational Biology</li>
            <li>Emily Rose, MD, Director for Pre-Health Undergraduate Studies Keck School of Medicine of USC</li>
            <li>Steven J. Ross Distinguished Professor of History</li>
            <li>Ilan Rotstein, Professor and Chair, Herman Ostrow School of Dentistry</li>
            <li>Lucille Rotstein, Clinical Associate Professor, Herman Ostrow School of Dentistry</li>
            <li>Kelly Sanders, Associate Professor of Civil and Environmental Engineering</li>
            <li>Susan Segal, Professor of Teaching, Department of English</li>
            <li>Soheil Shams, PhD USC Alumni</li>
            <li>Gil Shlamovitz, MD, FACEP, FAMIA, Chief Medical Informatics Officer and Professor of Clinical Emergency Medicine, Keck School of Medicine</li>
            <li>Dan Schnur, Adjunct Instructor, Annenberg School for Communication</li>
            <li>Sara L. Schwartz, Associate Teaching Professor, USC Suzanne Dworak-Peck School of Social Work</li>
            <li>Vanessa R. Schwartz, Director, Visual Studies Research Institute, Professor of Art History and History</li>
            <li>Yulia Shwartz, Assistant Professor of Stem Cell Biology and Regenerative Medicine</li>
            <li>Neil Siegel, The IBM Professor of Engineering Management; member of the National Academy of Engineering</li>
            <li>Andrew D. Smith, Professor of Quantitative and Computational Biology</li>
            <li>Lucio Soibelman Fred Champion Estate Chair in Engineering Professor. Viterbi School of Engineering</li>
            <li>Laura Stein, Parent of 2023 Graduate</li>
            <li>Michelle (Bielas) Stevens, Class of 2016</li>
            <li>Steven Siegel, M.D., Ph.D., Professor and Chair, Department of Psychiatry and the Behavioral Sciences, Chief Mental Health and Wellness Officer, Keck Medicine of USC</li>
            <li>William Stohl, Professor of Medicine</li>
            <li>Fengzhu Sun, Professor of Quantitative and Computational Biology</li>
            <li>David Tashman,  MD. Director, Department of Emergency Medicine USC Verdugo Hills Hospital, Associate Professor of Emergency Medicine</li>
            <li>Arnold Tiber, DDS, PhD, Associate Professor, Herman Ostrow School of Dentistry</li>
            <li>Jody Tolan, MBA, Ed.D., Assistant Professor, Marshall School of Business</li>
            <li>Sarah Townsend, Associate Professor of Management and Organization</li>
            <li>David Traum, Research Professor of Computer Science</li>
            <li>Shirley Traum, MSEE, Class of 1985, Trustee Scholar, Emma Josephine Bradley Bovard Award Recipient</li>
            <li>Jennifer Treweek, Ph.D. Gabilan Assistant Professor, Alfred E. Mann Department of Biomedical Engineering</li>
            <li>Stephanie Tully, Kenneth King Stonier Assistant Professor of Business Administration</li>
            <li>Kalinda Ukanwa, Assistant Professor of Marketing</li>
            <li>Barbara Van Noppen, PhD, LCSW Clinical Professor, Department of Psychiatry and the Behavioral Sciences, Keck Medicine of USC</li>
            <li>Larry Vincent, Associate Professor of the Practice of Marketing</li>
            <li>Andrew Viterbi, Presidential Chair Professor of Electrical Engineering (retired), Member, National Academy of Engineering, Member, National Academy of Sciences</li>
            <li>Cheryl Wakslak, Associate Professor of Management and Organization, Marshall School of Business</li>
            <li>Arieh Warshel, Nobel Laureate, Dana and David Dornsife Chair in Chemistry, Member, National Academy of Sciences</li>
            <li>Michael Waterman, Emeritus University Professor</li>
            <li>Mark Weinstein, Associate Professor Emeritus, Finance and Business Economics, Marshall School of Business</li>
            <li>Ruth Weisberg, Professor, Roski School of Art and Design</li>
            <li>Martin H. Weiss, MD, The Martin H Weiss Chair in Neurological Surgery (Emeritus), Keck School of Medicine</li>
            <li>Alan Willner, Andrew and Erna Viterbi Professorial Chair and Distinguished Professor of Electrical and Computer Engineering, Member, National Academy of Engineering</li>
            <li>Scott Wiltermuth, Professor of Management and Organization, Marshall School of Business</li>
            <li>Curt Wittig, Paul A. Miller Professor of Letters, Arts, and Sciences and Professor of Chemistry</li>
            <li>Regina Wittenberg Moerman, Accounting Circle Professor of Business Administration, Marshall School of Business</li>
            <li>Yael Wolinsky-Nahmias, Professor of the Practice of Environmental Studies and Political Science</li>
            <li>Vladimir Zelman MD PhD Professor Department of Anesthesiology, Foreign Member, Russian Academy of Science</li>
            <li>Michael Zyda, Emeritus Professor of Practice</li>
          </ul>

          <h4>Supporters outside USC:</h4>

          <ul className="list-unstyled">
            <li>Jodye Alcon</li>
            <li>Iris Bar</li>
            <li>Jamie Barsimantov, Ph.D.</li>
            <li>Isaac Bersuker, Academician ASM, Oden Institute, UT Texas</li>
            <li>Manijeh Breskin</li>
            <li>Cecile Cohan</li>
            <li>Alan Cohen</li>
            <li>Marina and Adam Cohn</li>
            <li>Michael Culver</li>
            <li>Shana Doronn, LCSW, Psy.D UCLA OCD IOP</li>
            <li>Michael Frenklach, Distinguished Professor of Mechanical Engineering, University of California, Berkeley</li>
            <li>Avital Hezkiya</li>
            <li>Donna L. Hoffman, Louis Rosenfeld Distinguished Professor, George Washington University</li>
            <li>Elise Horwich, LMFT</li>
            <li>Chana Karna Blugrind MAEd</li>
            <li>Julia Laskin, Professor of Chemistry, Purdue University</li>
            <li>Gal Kessler Rohs</li>
            <li>Maya Khirman</li>
            <li>David Kobrinetz, Director, Market Development- Community Access, Freenome</li>
            <li>Beny Neta,Professor, Naval Postgraduate School</li>
            <li>Tamar Neta</li>
            <li>Katie &amp; Amnon Rodan</li>
            <li>Elle Rohs</li>
            <li>Carla Rosenblum</li>
            <li>Annie Rosenschein-Bars Retired</li>
            <li>David Steigmann, Professor at UC Berkeley</li>
            <li>Dr. Yakir Englander (IAC, American Jewish Academy)</li>
            <li>Natalie Zhitnitsky, Speech Pathologist</li>
          </ul>

          <p>
            If you wish to sign the letter, please
            click <a href="https://docs.google.com/forms/d/e/1FAIpQLSfFpCP3qZ_rpi0feV5qMe_a2iv7etsjqpQ7GMcc-Sp_H_J8_g/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer">here</a>. The list of signatures is updated daily.
          </p>
        </div>
      </div>
    </Row>
  );
}

export default Authors;
