import React, { useState } from 'react';
import { Button, Modal, ModalBody,  FormGroup } from 'reactstrap';
import './share.scss';

function ShareLinks(props) {
  const {
    className
  } = props;

  const siteUrl = 'https://usc-faaz-10-7-2023.org';

  const wrapper = React.createRef();

  const [modal, setModal] = useState(false);

  const onLink = () => setModal(!modal);

  const onShare = (network) => {
    const urlFb = 'https://usc-faaz-10-7-2023.org';

    switch(network){
      case 'fb':
        const fbShare = 'https://www.facebook.com/sharer/sharer.php?u=' + urlFb;
        window.open(fbShare,
        'facebook-share-dialog',
        'width=800,height=600'
        );
        break;
      case 'twitter':
        const twitterShare='http://twitter.com/share?text=An Open Letter to the USC Community&url=https://usc-faaz-10-7-2023.org'
        window.open(twitterShare,
        'twitter-share-dialog',
        'width=800,height=600'
        );
        break;
      default:
        console.log('nothing');
    }
  }

  const onCopyLink = () => {
    wrapper.current.select();

    setTimeout(()=> {
      document.execCommand('copy');
      onLink();
    }, 250);
  }

  return (
    <div className={`share-wrapper ${props.layout ? props.layout : ''}`}>
      <Button className="btn btn-usc" onClick={()=>onShare('fb')}>Facebook</Button>
      <Button className="btn btn-usc" onClick={()=>onShare('twitter')}>Twitter</Button>
      <Button className="btn btn-usc" onClick={onLink}>Copy link</Button>

      <Modal isOpen={modal} toggle={onLink} className={className}>
        <ModalBody>
          <FormGroup>
            <input className="form-control" type="input" name="sitelink" id="sitelink" value={siteUrl} ref={wrapper} onChange={onCopyLink}/>
          </FormGroup>
          <Button className="btn" onClick={onCopyLink}>Copy</Button>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ShareLinks;
