import React from 'react';
import ShareLinks from '../ShareLinks/share';

import './petition.scss';

function Petition() {
  return (
    <div className="row">
      <div className="col">
        <div className="petition-wrapper">
          <div className="note-wrapper mb-3 font-weight-bold">October 10, 2023</div>
          <div className="row">
            <div className="col-xs-12 col-lg-3">
              <div className="d-none d-lg-block">
                <ShareLinks/>
              </div>
            </div>
            <div className="col-xs-12 col-lg-9">
              <div className="d-block d-lg-none">
                <ShareLinks/>
              </div>
              <p>
                Dear USC friends and colleagues,<br/>
                <br/>
                We are reaching out to you today to stand in support of the people of Israel who have suffered the worst acts of terrorism since the creation of the state.
              </p>

              <p>
                This act is a crime against humanity. Under the cover of a massive rocket attack, several hundred Hamas members in armed vehicles attacked towns, kibbutzim, and villages near the border. They moved from house to house, setting fire to some of them to force people out and shooting from point blank range at entire families. More Jews died in a single day than on any other day since the Holocaust. Young and old, from toddlers to the elderly, were executed in their homes and on the streets of their communities. The death toll has already exceeded 1,000 and many more were wounded. Over 150 people were kidnapped (including babies, children, elderly women, and disabled people) and taken to Gaza as hostages. The hostages are under threat of public execution. There is not a single home in Israel that has not been impacted by this tragedy, and the struggle is still ongoing.
              </p>

              <p>
                Many of us in the USC community have family, friends, and colleagues in Israel, and they need your support more than ever, as more horror stories come to light daily. Our faculty come from different nationalities, faiths, and political beliefs, but we all join in expressing our grief and support of the people of Israel as they face this unimaginable tragedy.
              </p>

              <p>
                We call on the USC community to stand with us and to condemn these acts of terrorism targeting civilians clearly and unequivocally. They clearly fall under the category of crimes against humanity.
              </p>

              <p>
                We end with the words of Jonathan Greenblatt, the CEO of the Anti-Defamation League, “There are some issues in public life that might prompt impassioned debate, then there are moments of unmistakable moral clarity that demand unequivocal, universal condemnation. This is one of those moments.”
              </p>

              <p>
                As a group of faculty and supporters of the University of Southern California, we offer our support to all students and members of the USC community who have been affected by this tragedy. We are here to help and support you.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Petition;
